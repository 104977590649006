import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import * as API from "../endpoint";
import Footer from "../pages/footer";
import Header from "../pages/header";

const View = () => {
  const [user, setUser] = useState({});
  console.log(API.GET_VULNERABILITY_API);

  const { vulnerabilityid } = useParams();

  useEffect(() => {
    axios
      .get(API.VIEW_VULNERABILITY_API(vulnerabilityid))
      .then((resp) => setUser({ ...resp.data[0] }));
  }, [vulnerabilityid]);

  return (
    <div>
      <Header />
      <div style={{ marginTop: "150px" }}>
        <div className="card">
          <div className="card-header">
            <p>User Object Type</p>
          </div>
          <div className="container">
            <strong>ID : </strong>
            <span>{vulnerabilityid}</span>
            <br />
            <br />
            <strong>Vulnerability Name : </strong>
            <span>{user.vulnerabilityname}</span>
            <br />
            <br />
            <strong>Threat : </strong>
            <span>{user.threat}</span>
            <br />
            <br />
            <strong>Mitigation Strategy : </strong>
            <span>{user.mitigationstrategy}</span>
            <br />
            <br />
            <strong>Contigency Plan : </strong>
            <span>{user.contigencyplan}</span>
            <br />
            <br />

            <Link to="/vulnerability">
              <button className="btn btn-edit">Go Back</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default View;
