import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import * as API from "../endpoint";
import Header from "../pages/header";
import Footer from "../pages/footer";
const initialState = {
  vulnerabilityname: " ",
  threat: " ",
  mitigationstrategy: " ",
  contigencyplan: " ",
};

const VulnerabilityAddEdit = () => {
  const [state, setState] = useState(initialState);
  console.log(API.GET_VULNERABILITY_API);

  const { vulnerabilityname, threat, mitigationstrategy, contigencyplan } =
    state;

  const navigate = useNavigate();

  const { vulnerabilityid } = useParams();

  useEffect(() => {
    if (vulnerabilityid) {
      axios
        .get(API.VIEW_VULNERABILITY_API(vulnerabilityid))
        .then((resp) => setState({ ...resp.data[0] }));
    }
  }, [vulnerabilityid]);

  const handlSubmit = (e) => {
    e.preventDefault();
    if (!vulnerabilityname) {
      toast.error("please provider value into each input field");
    } else {
      if (!vulnerabilityid) {
        axios
          .post(API.ADD_VULNERABILITY_API, {
            vulnerabilityname,
            threat,
            mitigationstrategy,
            contigencyplan,
          })
          .then(() => {
            setState({
              vulnerabilityname: " ",
              threat: " ",
              mitigationstrategy: " ",
              contigencyplan: " ",
            });
          })
          .catch((err) => toast.error(err.response.data));
        toast.success("Object added successfully");
      } else {
        axios
          .put(API.UPDATE_VULNERABILITY_API(vulnerabilityid), {
            vulnerabilityname,
            threat,
            mitigationstrategy,
            contigencyplan,
          })
          .then(() => {
            setState({
              vulnerabilityname: " ",
              threat: " ",
              mitigationstrategy: " ",
              contigencyplan: " ",
            });
          })
          .catch((err) => toast.error(err.response.data));
        toast.success(" update successfully");
      }
      setTimeout(() => navigate("/vulnerability"), 500);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  return (
    <div>
      <Header />
      <div style={{ marginTop: " 100px" }}>
        <form
          style={{
            margin: "auto",
            padding: "15px",
            maxWidth: "400px",
            alignContent: "center",
            fontFamily: "Poppins",
          }}
          onSubmit={handlSubmit}
        >
          <label htmlFor="vulnerabilityname">Vulnerability Name</label>
          <input
            type="text"
            id="vulnerabilityname"
            name="vulnerabilityname"
            placeholder="Enter vulnerability name"
            value={vulnerabilityname || " "}
            onChange={handleInputChange}
          />

          <label htmlFor="threat">Threat</label>
          <input
            type="text"
            id="threat"
            name="threat"
            placeholder="Enter threat"
            value={threat || " "}
            onChange={handleInputChange}
          />

          <label htmlFor="mitigationstrategy">Mitigation Strategy</label>
          <input
            type="text"
            id="mitigationstrategy"
            name="mitigationstrategy"
            placeholder="Enter mitigation strategy"
            value={mitigationstrategy || " "}
            onChange={handleInputChange}
          />

          <label htmlFor="contigencyplan">Contigency Plan</label>
          <input
            type="text"
            id="contigencyplan"
            name="contigencyplan"
            placeholder="Enter contigency plan "
            value={contigencyplan || " "}
            onChange={handleInputChange}
          />

          <input type="submit" value={vulnerabilityid ? "update" : "Save"} />
          <Link to="/vulnerability">
            <input className="btn btn-edit" type="button" value="go back" />
          </Link>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default VulnerabilityAddEdit;
